import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NoRecordFound from "../../components/NoRecordFound/NoRecordFound";
import {
  IndianTimeZoneFormat,
  fontFamilys,
  splitDateConversion,
} from "../../utils/Validations/ValidationsFn";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { SuccessMsg } from "../../components/SuccessMsg";
import { firstCharacterUppercase } from "../../utils/characterCases/Cases";
import { documentStatus } from "../../utils/Constant";
import { Typography12 } from "../../components/Typography/Typography";
// import CircularTableLoader from "../../components/Loader/CircularTableLoader";
export default function DenseTable(props: any) {
  const navigate = useNavigate();

  // const [chipTextColor, setchipTextColor] = useState("");
  const kycUserList = props ? props.props : [];
  const handleViewRequestOnClick = (item: any) => {
    // console.log(item, "check user item");
    let data = {
      user_id: item.user,
      status: item.due,
      referenceNumber: item.userRequestReference
        ? item.userRequestReference
        : "",
    };

    navigate("/app/reference", {
      state: data,
    });
  };
  const chipBackgroundColor = (value: any) => {
    if (value === "New") {
      return "rgba(255, 171, 0, 0.16)";
    } else if (value === "Approved") {
      return "rgba(54, 179, 126, 0.16)";
    } else {
      return "rgba(255, 86, 48, 0.16)";
    }
  };
  const chipTextColor = (value: any) => {
    if (value === "New") {
      return "rgba(183, 110, 0, 1)";
    } else if (value === "Approved") {
      return "rgba(27, 128, 106, 1)";
    } else {
      return "rgba(183, 29, 24, 1)";
    }
  };

  const chipBackgroundColorCurrentStatus = (value: any) => {
    if (value === "active") {
      return "rgba(54, 179, 126, 0.16)";
    } else {
      return "rgba(255, 86, 48, 0.16)";
    }
  };
  const chipTextColorCurrentStatus = (value: any) => {
    if (value === "active") {
      return "rgba(27, 128, 106, 1)";
    } else {
      return "rgba(183, 29, 24, 1)";
    }
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopOver = Boolean(anchorEl);
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 4,
              }}
            >
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                User Details
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Refered By
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Status
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                GST Reference Number
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Created on
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                borderBottom: "none",
                // px: 4,
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Profile Status
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
                // px: 4,
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Request
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={13}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Action
              </Typography>{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kycUserList.length > 0 &&
            kycUserList.map((item: any, index: any) => {
              return (
                <TableRow key={item._id || index}>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Stack direction={"row"} display={"flex"} spacing={1}>
                      <Avatar
                        sizes="xs"
                        sx={{ height: 30, width: 30 }}
                        src={item && item.aadharPhotoLink}
                      />
                      <Box>
                        <Stack>
                          <Typography
                            fontSize={12}
                            color={"#303981"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={700}
                          >
                            {item ? item.Legal_Name_of_Business : "N/A"}
                          </Typography>
                          <Stack direction={"row"} spacing={0.5}>
                            <Typography
                              fontSize={10}
                              color={"#212B36"}
                              sx={{ whiteSpace: "wrap" }}
                              fontWeight={500}
                              fontFamily={fontFamilys.publicSans}
                            >
                              {item ? item.GSTIN_of_the_entity : "N/A"}
                            </Typography>{" "}
                            {item.GSTIN_of_the_entity ? (
                              <>
                                <Tooltip
                                  title="Copy"
                                  placement="top"
                                  sx={{ p: 0 }}
                                >
                                  <IconButton
                                    onClick={() =>
                                      onCopy(item.GSTIN_of_the_entity)
                                    }
                                  >
                                    <Icon icon="eva:copy-fill" width={15} />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              ""
                            )}
                          </Stack>
                        </Stack>
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={12}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item && item.businessUser && item.businessUser.refferedBy
                        ? item.businessUser.refferedBy
                        : "N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {" "}
                    <Chip
                      label={item.due}
                      style={{
                        backgroundColor: chipBackgroundColor(item.due),
                        color: chipTextColor(item.due),
                        fontWeight: 600,
                        fontSize: 12,
                        lineHeight: "20px",
                        fontFamily: "Public Sans",
                      }}
                      sx={{
                        minheight: "24px",
                        minwidth: "30px",
                        "& .MuiChip-label": {
                          fontWeight: 700,
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Stack direction={"row"} spacing={0.5}>
                      <Typography
                        fontSize={12}
                        color={"#F58336"}
                        sx={{ whiteSpace: "wrap" }}
                        fontWeight={600}
                        fontFamily={fontFamilys.publicSans}
                      >
                        {item ? item.userRequestReference : "N/A"}
                      </Typography>{" "}
                      {item.userRequestReference ? (
                        <>
                          <Tooltip title="Copy" placement="top" sx={{ p: 0 }}>
                            <IconButton
                              onClick={() => onCopy(item.userRequestReference)}
                            >
                              <Icon icon="eva:copy-fill" width={15} />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <Typography
                      fontSize={12}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      fontWeight={400}
                    ></Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={11}
                      color={"#212B36"}
                      fontWeight={500}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {item && splitDateConversion(item.kycrequested)}
                    </Typography>
                    <Typography
                      fontSize={9}
                      color={"#212B36"}
                      fontWeight={500}
                      sx={{ pt: 0.2 }}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {item && IndianTimeZoneFormat(item.kycrequested)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {" "}
                    <Chip
                      label={
                        item.user
                          ? firstCharacterUppercase(item.user?.currentStatus)
                          : "N/A"
                      }
                      style={{
                        backgroundColor: chipBackgroundColorCurrentStatus(
                          item.user ? item.user?.currentStatus : ""
                        ),
                        color: chipTextColorCurrentStatus(
                          item.user ? item.user?.currentStatus : ""
                        ),
                        fontWeight: 700,
                        fontSize: 12,
                        lineHeight: "20px",
                        fontFamily: "Public Sans",
                      }}
                      sx={{
                        minheight: "24px",
                        minwidth: "30px",
                        "& .MuiChip-label": {
                          fontWeight: 700,
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {/* {item.due !== documentStatus.approved ? ( */}

                    <Tooltip
                      title={
                        !item.userRequestReference ? (
                          <Stack
                            fontSize="11px"
                            color="white"
                            bgcolor={"#7a6ec2"}
                            sx={{
                              fontWeight: "400",
                              p: 2,
                              m: "-10px",
                              borderRadius: "8px",
                            }}
                          >
                            Details unavailable due to document incompletion!
                          </Stack>
                        ) : (
                          ""
                        )
                      }
                      PopperProps={{
                        sx: {
                          pointerEvents: "none",
                        },
                      }}
                      sx={{
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "white",
                          boxShadow: "none",
                        },
                        backgroundColor: "transparent",
                      }}
                    >
                      <span>
                        <Button
                          size="small"
                          disabled={!item.userRequestReference}
                          onClick={() => handleViewRequestOnClick(item)}
                          sx={{
                            fontSize: "10px",
                            fontFamily: "Public Sans",
                            color: "var(--text-light-primary, #212B36)",
                            borderRadius: "8px",
                            fontWeight: 700,
                            border: "1px solid rgba(145, 158, 171, 0.32)",
                            textTransform: "none",
                            transition: "all 0.3s ease",
                            "&:hover": {
                              backgroundColor: "#36A979",
                              color: "white",
                            },
                          }}
                        >
                          View
                        </Button>
                      </span>
                    </Tooltip>

                    {/* ) : (
                      <Typography
                        variant="body2" // Change to body2 for a slightly smaller size
                        color="textSecondary"
                        align="center"
                        sx={{
                          fontWeight: 500, // Adjust weight for better readability
                          mt: 1, // Add margin-top for spacing
                        }}
                      >
                        N/A
                      </Typography>
                    )} */}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <div>
                      <Typography
                        onClick={(e: any) => handleClick(e, item.user)}
                      >
                        <MoreVertIcon fontSize="small" />
                      </Typography>

                      <Popover
                        id={item.businessUser ? item.businessUser._id : ""}
                        open={openPopOver}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          mt: -0.8,
                          ml: -2,
                          "& .MuiPaper-root": {
                            boxShadow: "unset",
                            bgcolor: "	#f1f1f1",
                          },
                        }}
                      >
                        <Box>
                          {selectedItem.currentStatus === "inActive" ? (
                            <Stack m={1.5}>
                              <Typography
                                fontSize={14}
                                fontFamily={"Public Sans"}
                                sx={{ cursor: "pointer" }}
                                fontWeight={600}
                                onClick={() =>
                                  props.handleClickActiveStatus(
                                    selectedItem._id
                                  )
                                }
                              >
                                Enable
                              </Typography>
                            </Stack>
                          ) : (
                            <Stack m={1.5}>
                              <Typography
                                fontSize={14}
                                fontFamily={"Public Sans"}
                                sx={{ cursor: "pointer" }}
                                fontWeight={600}
                                onClick={() =>
                                  props.handleClickInActiveStatus(
                                    selectedItem._id
                                  )
                                }
                              >
                                Disable
                              </Typography>
                            </Stack>
                          )}
                        </Box>
                      </Popover>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {kycUserList.length <= 0 ? (
        <NoRecordFound text1={"Business User"} text2={"business user"} />
      ) : (
        ""
      )}
    </>
    // </TableContainer>
  );
}
