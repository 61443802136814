import ApiService from "../../APIs/api";

export async function BusinessApprovedDocs(value: any, token: any) {
  if (token !== "" && token !== undefined && value) {
    await ApiService.setToken(token);
    const responseData: any = await ApiService.post(
      "admin/approveDocument",
      value
    );
    if (responseData.status === 200) {
      return responseData.data.Active;
    } else {
      return responseData;
    }
  }
}
export async function BusinessRejectedDocs(value: any, token: any) {
  if (token !== "" && token !== undefined && value) {
    const newToken = await ApiService.setToken(token);
    const responseData: any = await ApiService.post(
      "admin/rejectDocument",
      value
    );
    if (responseData.status === 200) {
      console.log(responseData, "res......data");
      return responseData.data.Active;
    } else {
      console.log(responseData);
    }
  }
}
