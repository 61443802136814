import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Stack,
  Avatar,
  Dialog,
  DialogActions,
  Container,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ImageRatio from "../../../assets/ImageRatio.svg";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import BusinessActivity from "./BusinessActivity";
import { BusinessRejectedDocs } from "../../../api/bussiness/businessApi";
import { Link } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Gst_Details from "./Gst_Details";
import AadharDetails from "./AadharDetails";
import { ErrorMsg } from "../../../components/ErrorMsg";
import ApiService from "../../../APIs/api";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { SuccessMsg } from "../../../components/SuccessMsg";
import { generatePresignedUrl } from "../../../hooks/presignUrl";
import CircularTableLoader from "../../../components/Loader/CircularTableLoader";

const BusinessReferenceNumber = () => {
  const [checkDocumentsApprovedOrNot, setCheckDocumentsApprovedOrNot] =
    useState<any>([]);

  // token from redux
  const token = useSelector((state: any) => state.user.userLogin.token);
  const [Status0, setStatus0] = useState(false);
  const [Status1, setStatus1] = useState(false);
  const [Status2, setStatus2] = useState(false);
  const [Status3, setStatus3] = useState(false);
  const navigate = useNavigate();
  const getPresignedUrl = async (resultFiles: any, value: number) => {
    if (!resultFiles) {
      return;
    }
    try {
      const resultFilesKeys = Object.keys(resultFiles);

      const responses = await Promise.all(
        resultFilesKeys.map(async (key) => {
          const response = await generatePresignedUrl(token, resultFiles[key]);
          return { key, response };
        })
      );

      responses.forEach(({ key, response }) => {
        resultFiles[key] = response;
      });
      if (value === 1) {
        setbusinessDetailsDocs(resultFiles);
      } else if (value === 2) {
        setAadharDetailsDocs(resultFiles);
      }
    } catch (error) {
      console.log(error);
      if (value === 1) {
        setbusinessDetailsDocs(resultFiles);
      } else if (value === 2) {
        setAadharDetailsDocs(resultFiles);
      }
    }
  };

  const updateStatus = (newStatus: Record<string, any>) => {
    setBusinessDetailsDocsStatus((prevStatus: any) => ({
      ...prevStatus,
      ...newStatus,
    }));
  };

  const [expandBusinessDetails, setexpandBusinessDetails] = useState(true);
  const [userBusinessDetails, setuserBusinessDetails] = useState<any>({});
  const [userContactDetails, setUserContactDetails] = useState<any>({});
  const [GstRemarksDialog, setGstRemarksDialog] = useState(false);
  const [businessDetailsFetchStatus, setBusinessDetailsFetchStatus] =
    useState(false);
  const [businessDetailsDocs, setbusinessDetailsDocs] = useState<any>({});
  const [BusinessDetailsDocsStatus, setBusinessDetailsDocsStatus] =
    useState<any>({});
  // Check if all values in the object are "Approved"
  const isApproveButtonDisabled = Object.values(
    BusinessDetailsDocsStatus
  ).every((status) => status === "Approved");
  console.log("BusinessDetailsDocsStatus", BusinessDetailsDocsStatus);
  const [DocsDialog, setDocsDialog] = useState(false);
  const [expandAadharDetails, setexpandAadharDetails] = useState(true);
  const [userAadharDetails, setuserAadharDetails] = useState<any>({});
  const [AadharDetailsDocs, setAadharDetailsDocs] = useState<any>({});
  const [AadharDetailsDocsStatus, setAadharDetailsDocsStatus] = useState<any>(
    {}
  );
  const [userAadharPhotoLink, setuserAadharPhotoLink] = useState("");
  const [UserAadharNumber, setUserAadharNumber] = useState("");

  const [showDocsImage, setShowDocsImage] = useState("");
  const [askForClarification, setaskForClarification] = useState("");
  const [finalStatusRejectBtnDisabled, setfinalStatusRejectBtnDisabled] =
    useState(false);
  const [finalStatusApprovedBtnDisabled, setfinalStatusApprovedBtnDisabled] =
    useState(true);
  const [expandAcitivityDetails, setexpandAcitivityDetails] = useState(true);
  const [rejectedBtnDisable, setrejectedBtnDisable] = useState(false);
  const location = useLocation();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleUserGstProfile = async () => {
    setexpandBusinessDetails(!expandBusinessDetails);
    if (expandBusinessDetails) {
      setBusinessDetailsFetchStatus(true);
      if (token !== "" && token !== undefined) {
        const setToken = await ApiService.setToken(token);
        const responseData: any = await ApiService.get(
          `/admin/userbusinessdetail?id=${location.state?.user_id?._id}`
        );
        if (responseData.status === 200) {
          setuserBusinessDetails(responseData.data.result.restResult);
          setbusinessDetailsDocs(responseData.data.result.resultFiles);
          setBusinessDetailsDocsStatus(responseData.data.result.status);
          setCheckDocumentsApprovedOrNot({
            ...checkDocumentsApprovedOrNot,
            ...responseData.data.result.status,
          });
          setBusinessDetailsFetchStatus(false);
        } else {
          console.log(responseData);
        }
      }
    }
  };
  const handleClose = () => {
    setbtndesabled(true);
    setGstRemarksDialog(false);
  };
  const handleDocsImage = (image: any) => {
    setShowDocsImage(image);
    setDocsDialog(!DocsDialog);
  };
  const [Remark, setRemark] = useState("");
  const [btndesabled, setbtndesabled] = useState(true);
  const handleOnchangeSetRemarks = (e: any) => {
    if (e.target.value !== "") {
      setRemark(e.target.value);
      setbtndesabled(false);
    } else {
      setbtndesabled(true);
    }
  };

  const openDialogForGst = (value: any) => {
    setaskForClarification(value);
    setGstRemarksDialog(true);
  };
  const [aadharLoading, setaadharLoading] = useState(false);
  const handleUserAdharProfile = async () => {
    setaadharLoading(true);
    setexpandAadharDetails(!expandAadharDetails);
    if (expandAadharDetails) {
      setAadharDetailsDocsStatus(true);
      if (token !== "" && token !== undefined) {
        const setToken = await ApiService.setToken(token);
        console.log("location.state", location.state);
        const responseData: any = await ApiService.get(
          "/admin/businessrepresentativedetail",
          {
            id: location.state ? location.state.user_id : "",
          }
        );
        if (responseData.status === 200) {
          setuserAadharDetails(responseData.data.result.restResult);
          setUserAadharNumber(
            responseData.data.result.resultAadharPhoto.aadharNumber
          );
          setuserAadharPhotoLink(
            responseData.data.result.resultAadharPhoto.aadharPhotoLink
          );
          setAadharDetailsDocs(responseData.data.result.resultFiles);
          setAadharDetailsDocsStatus(responseData.data.result.status);

          setCheckDocumentsApprovedOrNot({
            ...checkDocumentsApprovedOrNot,
            ...responseData.data.result.status,
          });
          setAadharDetailsDocsStatus(false);
        } else {
          console.log(responseData);
        }
        let resultFiles = responseData.data.result.resultFiles;
        setAadharDetailsDocs(responseData.data.result.resultFiles);
        await getPresignedUrl(resultFiles, 2);
        setAadharDetailsDocsStatus(responseData.data.result.status);
        updateStatus(responseData.data.result.status);
        setaadharLoading(false);
      } else {
        setaadharLoading(false);
        ErrorMsg("Something Went Wrong, Please Try Again !");
      }
    }
    setaadharLoading(false);
  };

  console.log(checkDocumentsApprovedOrNot);
  console.log(finalStatusApprovedBtnDisabled);

  const checkApprovedBtn = (updatedKeys: any) => {
    let status: any = [];

    for (let x in updatedKeys) {
      status.push(updatedKeys[x]);
    }

    if (status.length < 3) return;

    if (status.includes("Under_Review")) {
      setfinalStatusApprovedBtnDisabled(true);
    } else {
      setfinalStatusApprovedBtnDisabled(false);
    }
  };

  useEffect(() => {
    checkApprovedBtn(checkDocumentsApprovedOrNot);
  }, [checkDocumentsApprovedOrNot]);

  const updateDocumentStatus = (key: string, value: string) => {
    let updatedKeys: any = {};
    for (let x in checkDocumentsApprovedOrNot) {
      if (key === x) {
        updatedKeys[key] = value;
      } else {
        updatedKeys[x] = checkDocumentsApprovedOrNot[x];
      }
    }
    setCheckDocumentsApprovedOrNot(updatedKeys);
  };

  const ApprovedDocs = async (filename: string) => {
    let flag;
    if (filename === "aadharFileUrl") {
      flag = "AdminAadhaarS1Verified";
    } else if (filename === "aadharBackUrl") {
      flag = "AdminAadhaarS2Verified";
    } else if (filename === "PANFile") {
      flag = "AdminPanVerified";
    } else {
      flag = "AdminGSTVerified";
    }
    if (token !== "" && token !== undefined) {
      let value = {
        _flag: flag ? flag : "",
        status: "Approved",
        id: location.state ? location.state.user_id._id : "",
      };

      if (token !== "" && token !== undefined && value) {
        const setToken = await ApiService.setToken(token);
        const responseData: any = await ApiService.post(
          "/admin/approveDocument",
          value
        );
        if (responseData.status === 200) {
          if (filename === "aadharFileUrl") {
            setStatus1(true);
          } else if (filename === "aadharBackUrl") {
            setStatus2(true);
          } else if (filename === "PANFile") {
            setStatus3(true);
          } else if (filename === "AdminGSTVerified") {
            setStatus0(true);
          }
          updateDocumentStatus(flag, "Approved");
        } else {
          return responseData;
        }
      }
    }
  };
  const RejectDocs = async () => {
    let flag;
    let docNameKey;
    if (askForClarification === "aadharFileUrl") {
      flag = "AdminAadhaarS1Verified";
      docNameKey = "Admin_AadhaarS1_Verification_Clarification";
      setStatus1(false);
    } else if (askForClarification === "aadharBackUrl") {
      flag = "AdminAadhaarS2Verified";
      docNameKey = "Admin_AadhaarS2_Verification_Clarification";
      setStatus2(false);
    } else if (askForClarification === "PANFile") {
      flag = "AdminPanVerified";
      docNameKey = "Admin_Pan_Verification_Clarification";
      setStatus3(false);
    } else {
      flag = "AdminGSTVerified";
      setStatus0(false);
      docNameKey = "Admin_GST_Verification_Clarification";
    }
    if (token !== "" && token !== undefined) {
      let value = {
        filename: flag ? flag : "",
        status: "Rejected",
        id: location.state ? location.state.user_id._id : "",
        docNameKey: docNameKey,
        clarification: Remark,
      };
      const status = await BusinessRejectedDocs(value, token);
      if (status) {
        setGstRemarksDialog(false);
        updateDocumentStatus(flag, "Rejected");
      }
    }
  };
  const changeTextAdharProfile = (value: any) => {
    if (value === "aadharCO") {
      return "Care_of";
    } else if (value === "aadharGender") {
      return "Gender";
    } else if (value === "aadharCountry") {
      return "Country";
    } else if (value === "aadharPO") {
      return "PO";
    } else if (value === "nameInAadhaar") {
      return "Name";
    } else if (value === "distInAadhar") {
      return "Distict";
    } else if (value === "aadharPincode") {
      return "Pincode";
    } else if (value === "aadharNumber") {
      return "Aadhar No";
    } else if (value === "aadharDOB") {
      return "Date of Birth";
    } else if (value === "_id") {
      return "Id";
    } else if (value === "aadharState") {
      return "State";
    } else if (value === "aadharHouse") {
      return "House";
    } else if (value === "aadharStreet") {
      return "Street";
    }
  };
  const handleOnClickFinalStatus = async (value: any) => {
    if (token !== "" && token !== undefined) {
      let body = {
        key: value,
        id: location.state ? location.state?.user_id?._id : "",
      };

      const newToken = await ApiService.setToken(token);
      const responseData: any = await ApiService.post(
        "/admin/finalstatus",
        body
      );
      if (responseData.status === 200) {
        if (value === "Approved") {
          setfinalStatusApprovedBtnDisabled(true);
        } else {
          setfinalStatusRejectBtnDisabled(true);
        }
        navigate("/app/userlist");
      } else {
        setfinalStatusRejectBtnDisabled(true);
      }
      navigate("/app/userlist");
    } else {
      if (value === "Approved") {
        setfinalStatusApprovedBtnDisabled(false);
      } else {
        setfinalStatusRejectBtnDisabled(false);
      }
    }
  };
  const handleProfileActivity = async (value: any) => {
    setexpandAcitivityDetails(false);
  };

  const handleOnClickRejectedStatus = async (value: any) => {
    setrejectedBtnDisable(true);
    let body = {
      key: value,
      id: location.state ? location.state.user_id._id : "",
    };
    await ApiService.setToken(token);
    const responseData: any = await ApiService.post("/admin/finalstatus", body);
    if (responseData.status === 200) {
      SuccessMsg("KYC documents rejected successfully");
      setfinalStatusApprovedBtnDisabled(true);
    } else {
      setrejectedBtnDisable(false);
      ErrorMsg("Something Went wrong");
    }
  };

  const [dataArray, setDataArray] = useState([1, 2, 3]);
  const maxAllowedLength = 5;

  const isButtonDisabled = dataArray.length >= maxAllowedLength;

  return (
    <Box
      justifyContent={"center"}
      component={"div"}
      sx={{
        minHeight: "80vh",
        height: "100%",
        pb: 4,
      }}
    >
      <Stack>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"18px"}
          sx={{ ml: 8, mt: 4 }}
          fontWeight={700}
          color={"#000000"}
        >
          Request Reference Number #
          {location?.state !== undefined
            ? location?.state?.referenceNumber
            : ""}
        </Typography>
      </Stack>

      {/* Step 1 Boxes */}
      <Box boxShadow={0} mx={8} my={2}>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            borderRadius: "12px",
            "&.Mui-expanded": {
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: "12px",
              backgroundColor: "transparent",
            },
          }}
        >
          <AccordionSummary
            onClick={handleUserGstProfile}
            expandIcon={
              <>
                <ExpandMoreIcon />
              </>
            }
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              mx={4}
              mt={1}
              component={"h5"}
              fontWeight={700}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Businesses Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {businessDetailsFetchStatus ? (
              <Box display={"flex"} justifyContent={"center"}>
                <CircularTableLoader />
              </Box>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>
                <Stack
                  mx={4}
                  mt={-6}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <Item sx={{ borderRadius: "12px" }}>
                    <Stack justifyContent={"flex-end"} spacing={0.5}>
                      <Typography
                        fontFamily={"Public Sans"}
                        fontSize={12}
                        display="flex"
                        alignItems="center"
                        color={"#212B36"}
                      >
                        <PhoneIcon sx={{ mr: 1, color: "green" }} />
                        Mobile: {userContactDetails?.mobile}
                      </Typography>
                      <Typography
                        fontFamily={"Public Sans"}
                        fontSize={12}
                        display="flex"
                        alignItems="center"
                        color={"#212B36"}
                      >
                        <MailOutlineIcon sx={{ mr: 1, color: "#3b82f680" }} />{" "}
                        Email: {userContactDetails?.email}
                      </Typography>
                    </Stack>
                  </Item>
                </Stack>

                <Grid
                  container
                  sx={{
                    mt: 1,
                    px: 2,
                    "& .MuiPaper-root": {
                      px: "12px",
                      py: 1,
                    },
                  }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Gst_Details
                    loading={businessDetailsFetchStatus}
                    gst_Details={userBusinessDetails}
                  />
                  <Grid
                    container
                    sx={{
                      py: 2,
                      "& .MuiPaper-root": {
                        // padding: "2px",
                        px: "12px",
                        py: 1,
                      },
                    }}
                  >
                    {Object.keys(businessDetailsDocs).map(
                      (value: any, index: any) => {
                        return (
                          <Grid item sx={{ px: "12px", py: "7px" }} key={index}>
                            <Item>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={11}
                                color={"#919EAB"}
                                mb={1}
                              >
                                Business Doucment {index + 1}
                              </Typography>
                              <img
                                src={businessDetailsDocs[value]}
                                onClick={() =>
                                  handleDocsImage(businessDetailsDocs[value])
                                }
                                alt=""
                                height={"89px"}
                                width={"150px"}
                              />

                              {BusinessDetailsDocsStatus &&
                              BusinessDetailsDocsStatus.AdminGSTVerified !==
                                "Approved" ? (
                                <Box>
                                  {!Status0 ? (
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        ApprovedDocs("AdminGSTVerified")
                                      }
                                      sx={{
                                        mt: 2,
                                        textTransform: "none",
                                        backgroundColor:
                                          "rgba(0, 171, 85, 0.16)",
                                        borderRadius: "8px",
                                        px: "48px",
                                        fontWeight: 700,
                                        fontSize: "13px",
                                        lineHeight: "22px",
                                        color: "rgba(0, 123, 85, 1)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 171, 85, 0.16)",
                                        },
                                      }}
                                    >
                                      Approve
                                    </Button>
                                  ) : (
                                    <Button
                                      size="small"
                                      disabled={Status0}
                                      sx={{
                                        mt: 2,
                                        textTransform: "none",
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        px: "48px",
                                        fontWeight: 700,
                                        fontSize: "13px",
                                        lineHeight: "22px",
                                        color: "rgba(0, 123, 85, 1)",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                        border: "1px solid green",
                                      }}
                                    >
                                      <TaskAltIcon
                                        fontSize="small"
                                        sx={{ px: 2 }}
                                      />
                                    </Button>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <Button
                                    size="small"
                                    disabled={true}
                                    sx={{
                                      mt: 1,
                                      textTransform: "none",
                                      px: 2,
                                      backgroundColor:
                                        "rgba(145, 158, 171, 0.24)",
                                      borderRadius: "8px",
                                      fontWeight: 700,
                                      fontSize: "13px",
                                      lineHeight: "22px",
                                      color: "dark",
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(145, 158, 171, 0.24)",
                                      },
                                    }}
                                  >
                                    <Typography
                                      fontSize={14}
                                      fontFamily={"Public Sans"}
                                      fontWeight={500}
                                    >
                                      {" "}
                                      Already Approved
                                    </Typography>
                                  </Button>
                                </Box>
                              )}

                              <Box>
                                <Button
                                  size="small"
                                  sx={{
                                    mt: 2,
                                    textTransform: "none",
                                    borderRadius: "8px",
                                    px: "9px",
                                    color: "black",
                                    fontWeight: 700,
                                    fontSize: "13px",
                                    lineHeight: "22px",
                                    border:
                                      "2px solid rgba(145, 158, 171, 0.32)",
                                  }}
                                  onClick={() => openDialogForGst(value)}
                                >
                                  Reject
                                </Button>
                              </Box>
                            </Item>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
                <Dialog open={DocsDialog} style={{ borderRadius: "10px" }}>
                  <Container sx={{ pt: 2 }}>
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      <img
                        src={showDocsImage !== "" ? showDocsImage : ImageRatio}
                        style={{
                          height: "400px",
                          width: "500px",
                        }}
                        alt=""
                      />
                    </Stack>
                  </Container>
                  <DialogActions
                    sx={{
                      justifyContent: "end",
                      textAlign: "end",
                      px: 4,
                      mb: 2,
                    }}
                  >
                    <Button
                      sx={{ px: 4 }}
                      style={{
                        fontFamily: "Public Sans",
                        color: "white",
                        width: "auto",
                        fontSize: "14px",
                        backgroundColor: "#303981",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontWeight: "700",
                      }}
                      onClick={() => setDocsDialog(false)}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </AccordionDetails>
          {/*  */}
          <Dialog onClose={handleClose} open={GstRemarksDialog}>
            <Box sx={{ mx: 4, my: 2 }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
              >
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"20px"}
                  component={"h4"}
                  sx={{ my: 2 }}
                  lineHeight={"36px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  color={"rgba(51, 74, 103, 1)"}
                >
                  Are you sure ?
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography color={"rgba(85, 100, 134, 1)"}>
                  * Please list the reason for rejection and re-upload
                </Typography>
                <TextField
                  label="Remarks"
                  size="small"
                  onChange={handleOnchangeSetRemarks}
                  type="text"
                  name="UserAadharNumber"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 40,
                      minWidth: "23vw",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />{" "}
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                spacing={2}
                my={4}
              >
                <Button
                  size="small"
                  onClick={handleClose}
                  sx={{
                    mt: 4,
                    textTransform: "none",
                    backgroundColor: "rgba(85, 100, 134, 1)",
                    borderRadius: "8px",
                    px: "44px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(85, 100, 134, 1)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  onClick={RejectDocs}
                  disabled={btndesabled}
                  sx={{
                    mt: 2,
                    textTransform: "none",
                    backgroundColor: "rgba(255, 86, 48, 1)",
                    borderRadius: "8px",
                    px: "44px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",

                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(255, 86, 48, 1)",
                    },
                  }}
                >
                  Reject
                </Button>
              </Stack>
            </Box>
          </Dialog>
        </Accordion>
      </Box>

      {/* Step 2 Boxes */}
      <Box boxShadow={0} mx={8} my={2}>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            borderRadius: "12px",
            "&.Mui-expanded": {
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: "12px",
            },
          }}
        >
          <AccordionSummary
            onClick={handleUserAdharProfile}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              mx={4}
              mt={1}
              component={"h5"}
              fontWeight={700}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Authorised Business Representative Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {aadharLoading ? (
              <>
                <CircularTableLoader />
              </>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>

                <>
                  <Box component="form" sx={{ px: 4 }}>
                    <Stack direction={"row"} sx={{ my: 1.5 }}>
                      <TextField
                        disabled
                        label="Your Aadhar Number"
                        value={UserAadharNumber}
                        size="small"
                        type="text"
                        name="UserAadharNumber"
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            borderColor: "#919EAB",
                            fontWeight: "500",
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 40,
                            minWidth: "23vw",
                          },
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: "14px",
                            Height: "20px",
                          },
                        }}
                      />{" "}
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{ ml: "20px", mt: 1 }}
                      >
                        <>
                          <DoneIcon
                            style={{ fontSize: "15px", color: "#36B37E" }}
                          />{" "}
                          <Typography
                            fontWeight={600}
                            fontSize={11}
                            fontFamily={"Public Sans"}
                            color={"#36B37E"}
                          >
                            {" "}
                            Business Details Fetched SuccessFully
                          </Typography>
                        </>
                      </Stack>
                    </Stack>
                    <Box>
                      <Grid
                        display={"flex"}
                        direction={"row"}
                        sx={{ fontSize: "12px" }}
                      >
                        <Grid container>
                          <Typography
                            component="div"
                            fontFamily={"Public Sans"}
                            fontSize={19}
                            fontWeight={700}
                            lineHeight={"30px"}
                            color={"#212B36"}
                          >
                            <Grid
                              container
                              sx={{
                                mt: 1,
                                "& .MuiPaper-root": {
                                  px: "12px",
                                  py: 1,
                                },
                              }}
                              spacing={{ md: 1 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              <AadharDetails aadhar_info={userAadharDetails} />
                              <Grid
                                container
                                sx={{
                                  py: 2,
                                }}
                              >
                                {Object.keys(AadharDetailsDocs).map(
                                  (value: any, index: any) => {
                                    return (
                                      <Grid
                                        style={{
                                          padding: "12px",
                                        }}
                                        key={index}
                                      >
                                        <Item>
                                          <Typography
                                            fontFamily={"Public Sans"}
                                            fontSize={12}
                                            color={"#919EAB"}
                                            mb={1}
                                          >
                                            Business Doucment {index + 1}
                                          </Typography>
                                          <img
                                            src={
                                              AadharDetailsDocs[value] !== ""
                                                ? AadharDetailsDocs[value]
                                                : ImageRatio
                                            }
                                            onClick={() =>
                                              handleDocsImage(
                                                AadharDetailsDocs[value]
                                              )
                                            }
                                            alt=""
                                            height={"89px"}
                                            width={"150px"}
                                          />
                                          {index === 0 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS1Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status1 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "dark",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {index === 1 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS2Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status2 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "dark",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {index === 2 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS2Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status3 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "grey",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          <Box>
                                            <Button
                                              size="small"
                                              sx={{
                                                mt: 2,
                                                textTransform: "none",
                                                borderRadius: "8px",
                                                px: "9px",
                                                color: "black",
                                                fontWeight: 700,
                                                fontSize: "13px",
                                                lineHeight: "22px",
                                                border:
                                                  "2px solid rgba(145, 158, 171, 0.32)",
                                              }}
                                              onClick={() =>
                                                openDialogForGst(value)
                                              }
                                            >
                                              Reject
                                            </Button>
                                          </Box>
                                        </Item>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          </Typography>
                        </Grid>
                        <Grid mx={3}>
                          <Item sx={{ borderRadius: "12px" }}>
                            <Avatar
                              alt=""
                              src={
                                userAadharPhotoLink
                                  ? userAadharPhotoLink
                                  : "/static/images/avatar/1.jpg"
                              }
                              sx={{ width: 150, height: 160 }}
                            />
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/*  */}
      <Box boxShadow={0} mx={8} my={3}>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            boxShadow:
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
            borderRadius: "12px",
            "&.Mui-expanded": {
              boxShadow:
                "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              borderRadius: "12px",
              backgroundColor: "transparent",
            },
          }}
        >
          <AccordionSummary
            onClick={handleProfileActivity}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              component={"h5"}
              fontWeight={700}
              mx={4}
              py={1.5}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Activity
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expandAcitivityDetails ? (
              <CircularTableLoader />
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>
                <BusinessActivity />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ mx: 8 }}>
        <Grid container>
          <Grid item xs={10}>
            <Link to={"/app/userlist"} style={{ textDecoration: "none" }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"start"}
              >
                <ArrowLeftIcon sx={{ mt: 0.15 }} />
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={15}
                  fontWeight={700}
                  lineHeight={"26px"}
                  color={"Black"}
                >
                  Back
                </Typography>
              </Stack>{" "}
            </Link>
          </Grid>
          <Grid xs={2}>
            {location.state.status == "New" ? (
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"end"}
                spacing={2}
              >
                {" "}
                <Button
                  size="small"
                  onClick={() => handleOnClickRejectedStatus("Rejected")}
                  disabled={rejectedBtnDisable}
                  sx={{
                    mt: 2,
                    textTransform: "none",
                    backgroundColor: !rejectedBtnDisable
                      ? "var(--error-main, #FF5630)"
                      : "rgba(145, 158, 171, 0.24)",

                    borderRadius: "8px",
                    display: "inline-flex",
                    padding: "11px 28px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: !rejectedBtnDisable
                        ? "var(--error-main, #FF5630)"
                        : "rgba(145, 158, 171, 0.24)",
                    },
                  }}
                >
                  Reject
                </Button>
                <Button
                  size="small"
                  disabled={finalStatusApprovedBtnDisabled}
                  onClick={() => handleOnClickFinalStatus("Approved")}
                  sx={{
                    mt: 2,
                    display: "inline-flex",
                    padding: "11px 22px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    textTransform: "none",
                    backgroundColor: !finalStatusApprovedBtnDisabled
                      ? "var(--success-main, #36B37E)"
                      : "rgba(145, 158, 171, 0.24)",
                    borderRadius: "8px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: !finalStatusApprovedBtnDisabled
                        ? "var(--success-main, #36B37E)"
                        : "rgba(145, 158, 171, 0.24)",
                    },
                  }}
                >
                  Approve
                </Button>
              </Stack>
            ) : (
              <Button
                size="small"
                disabled={true}
                sx={{
                  mt: 1,
                  textTransform: "none",
                  px: 2,
                  backgroundColor: "rgba(145, 158, 171, 0.24)",
                  borderRadius: "8px",
                  fontWeight: 700,
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: "dark",
                  "&:hover": {
                    backgroundColor: "rgba(145, 158, 171, 0.24)",
                  },
                }}
              >
                <Typography
                  fontSize={14}
                  fontFamily={"Public Sans"}
                  fontWeight={500}
                >
                  {" "}
                  Already {location.state?.status}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BusinessReferenceNumber;
