import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
console.log("process.env", process.env);
const apiService = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const ApiService = {
  getUserCoordinates: () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords, timestamp } = position;
          const LocationInfoData = {
            timestamp: timestamp,
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
          };
          resolve(LocationInfoData);
          return LocationInfoData;
        },
        (error) => {
          console.log(error, "Error from geolocation API");
          // alert(
          //   "Please Enable the location Access, if moving forward with this app"
          // );
          reject(error);
          return error;
        }
      );
    });
  },

  setToken: (token: any) => {
    apiService.defaults.headers.common["token"] = `${token}`;
  },

  get: async (endpoint: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    try {
      const response = await apiService.get(endpoint, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  post: async (endpoint: any, data: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.post(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  put: async (endpoint: any, data: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.put(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  patch: async (endpoint: any, data: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.patch(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  delete: async (endpoint: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.delete(endpoint, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },
};

export default ApiService;
